import Ajax from './ajax'

class CompetitionAjax extends Ajax {
  getCompetitionRows() {
    return this.ajax().get(`/competition`)
  }

  getCompetitionRow(id) {
    return this.ajax().get(`/competition/${id}`)
  }

  createCompetitionRow(data) {
    return this.ajax().post(`/competition`, data)
  }

  updateCompetitionRow(id, data) {
    return this.ajax().post(`/competition/${id}/update`, data)
  }

  deleteCompetitionRow(id) {
    return this.ajax().get(`/competition/${id}/delete`)
  }

  editingCompetitionRow(id, data) {
    return this.ajax().post(`/competition/${id}/editing`, data)
  }
}

const competitionAjax = new CompetitionAjax()
export default competitionAjax
