// export const PUSHER_KEY = 'b662ceef1ae1d7704769'
export const PUSHER_KEY = '0252507bd4600e9a547d'
export const CHANNELS = {
  SUMMARY: 'summary',
  FORM: 'form',
  CHANNEL_WM_ACCOUNT: 'CHANNEL_WM_ACCOUNT',
  LEADERBOARD: 'LEADERBOARD',
  COMPETITION: 'COMPETITION',
}

export const EVENTS = {
  UPDATE: 'summary-update',
  FORM_UPDATE: 'form-update',
  WM_ACCOUNT_UPDATE: 'WM_ACCOUNT_UPDATE',
  LEADERBOARD_UPDATE: 'LEADERBOARD_UPDATE',
  EVENT_ROW_EDITING: 'EVENT_ROW_EDITING',
  EVENT_NEW_ROW: 'EVENT_NEW_ROW',
  EVENT_ROW_UPDATED: 'EVENT_ROW_UPDATED',
  EVENT_ROW_DELETED: 'EVENT_ROW_DELETED',
}