export const REPORT_RANGES = {
  EIGHT_STEPS: 'eight-steps',
  DAILY: 'daily',
  WEEKLY_EDU: 'weekly-edu',
  WEEKLY_GENERAL: 'weekly-general',
  MONTHLY_GENERAL: 'monthly-general',
  CURRENT_STATUS: 'current-status',
}

export const CURRENT_STATUS_REPORT_TYPES = {
  educating_sheep: 'educating_sheep',
  active_swat: 'active_swat',
  active_gskt: 'active_gskt',
  child_1l: 'child_1l',
}

export const REPORT_TYPES = {
  DT: 'DT',
  HH: 'HH',
  BT_HS: 'BT_HS',
  BT: 'BT',
  TP_1L_HS: 'TP_1L_HS',
  TP_1L: 'TP_1L',
  TP_4L_HS: 'TP_4L_HS',
  TP_4L: 'TP_4L',
  MCXD: 'MCXD',
  Laxaro_HS: 'Laxaro_HS',
  Laxaro: 'Laxaro',
}

export const EIGHT_STEPS_REPORT_TYPES = {
  step_1: 'step_1',
  step_2: 'step_2',
  step_3: 'step_3',
  step_4: 'step_4',
  step_5: 'step_5',
  step_6: 'step_6',
  step_7: 'step_7',
  step_8: 'step_8',
}

export const WEEKLY_GENERAL_REPORT_TYPES_SHORT = {
  'BT': 'BT',
  '1L_3L': '1L_3L',
  '4L': '4L',
  'GSKT': 'GSKT',
  'NTD_8_plus': 'NTD_8_plus',
  'NTD_8_minus': 'NTD_8_minus',
  'child_1l': 'child_1l',
  'educating_sheep': 'educating_sheep',
  'active_gskt': 'active_gskt',
  'active_swat': 'active_swat',
  'KVT_male': 'KVT_male',
  'KVT_female': 'KVT_female',
  'WM': 'WM',
}

export const WEEKLY_GENERAL_REPORT_TYPES = {
  'BT': 'BT',
  '1L_3L': '1L_3L',
  '4L': '4L',
  'GSKT': 'GSKT',
  'NTD_8_plus': 'NTD_8_plus',
  'NTD_8_minus': 'NTD_8_minus',
  'child_1l': 'child_1l',
  'educating_sheep': 'educating_sheep',
  'active_gskt': 'active_gskt',
  'active_swat': 'active_swat',
  'KVT_male': 'KVT_male',
  'KVT_female': 'KVT_female',
  'WM': 'WM',
  'BT_plan': 'BT_plan',
  '1L_3L_plan': '1L_3L_plan',
  '4L_plan': '4L_plan',
  'GSKT_plan': 'GSKT_plan',
  'NTD_8_plus_plan': 'NTD_8_plus_plan',
  'NTD_8_minus_plan': 'NTD_8_minus_plan',
  'child_1l_plan': 'child_1l_plan',
  'educating_sheep_plan': 'educating_sheep_plan',
  'active_gskt_plan': 'active_gskt_plan',
  'active_swat_plan': 'active_swat_plan',
  'KVT_male_plan': 'KVT_male_plan',
  'KVT_female_plan': 'KVT_female_plan',
  'WM_plan': 'WM_plan',
}

export const MONTHLY_GENERAL_REPORT_TYPES_SHORT = {
 // Trudo
 DT: 'DT',
 HH: 'HH',
 BT: 'BT',
 'attendance': 'attendance',
 'menchixedec': 'menchixedec',

 // TP
 '1L_3L': '1L_3L',
 '4L': '4L',
 'MCXD': 'MCXD',

 // Giao duc
 'new_member': 'new_member',
 'member_1': 'member_1',
 'member_2': 'member_2',
 'NTD_edu': 'NTD_edu',
 'role_member': 'role_member',

 // NGV
 'NTD_NGV': 'NTD_NGV',
 'KVT_temp': 'KVT_temp',
 'KVT_official': 'KVT_official',
 'DVT_temp': 'DVT_temp',
 'DVT_official': 'DVT_official',
 'NQLNTP': 'NQLNTP',
 'NQLNCH': 'NQLNCH',

 // Phan tach
 'IUBA': 'IUBA',
 'WM': 'WM',
 'SION': 'SION',
 'branch': 'branch',
}

export const MONTHLY_GENERAL_REPORT_TYPES = {
  // Trudo
  DT: 'DT',
  HH: 'HH',
  BT: 'BT',
  'attendance': 'attendance',
  'menchixedec': 'menchixedec',

  // TP
  '1L_3L': '1L_3L',
  '4L': '4L',
  'MCXD': 'MCXD',

  // Giao duc
  'new_member': 'new_member',
  'member_1': 'member_1',
  'member_2': 'member_2',
  'NTD_edu': 'NTD_edu',
  'role_member': 'role_member',

  // NGV
  'NTD_NGV': 'NTD_NGV',
  'KVT_temp': 'KVT_temp',
  'KVT_official': 'KVT_official',
  'DVT_temp': 'DVT_temp',
  'DVT_official': 'DVT_official',
  'NQLNTP': 'NQLNTP',
  'NQLNCH': 'NQLNCH',

  // Phan tach
  'IUBA': 'IUBA',
  'WM': 'WM',
  'SION': 'SION',
  'branch': 'branch',

  // Trudo
  DT_plan: 'DT_plan',
  HH_plan: 'HH_plan',
  BT_plan: 'BT_plan',
  'attendance_plan': 'attendance_plan',
  'menchixedec_plan': 'menchixedec_plan',

  // TP
  '1L_3L_plan': '1L_3L_plan',
  '4L_plan': '4L_plan',
  'MCXD_plan': 'MCXD_plan',

  // Giao duc
  'new_member_plan': 'new_member_plan',
  'member_1_plan': 'member_1_plan',
  'member_2_plan': 'member_2_plan',
  'NTD_edu_plan': 'NTD_edu_plan',
  'role_member_plan': 'role_member_plan',

  // NGV
  'NTD_NGV_plan': 'NTD_NGV_plan',
  'KVT_temp_plan': 'KVT_temp_plan',
  'KVT_official_plan': 'KVT_official_plan',
  'DVT_temp_plan': 'DVT_temp_plan',
  'DVT_official_plan': 'DVT_official_plan',
  'NQLNTP_plan': 'NQLNTP_plan',
  'NQLNCH_plan': 'NQLNCH_plan',

  // Phan tach
  'IUBA_plan': 'IUBA_plan',
  'WM_plan': 'WM_plan',
  'SION_plan': 'SION_plan',
  'branch_plan': 'branch_plan',
}

export const WEEKLY_EDU_REPORT_TYPES = {
  men: 'men',
  women: 'women',
  boys: 'boys',
  girls: 'girls',
}

export const WEEKLY_EDU_REPORT_FIELDS = [
  {
    label: 'Tráng niên',
    key: WEEKLY_EDU_REPORT_TYPES.men
  },
  {
    label: 'Phụ nữ',
    key: WEEKLY_EDU_REPORT_TYPES.women
  },
  {
    label: 'Nam thanh niên',
    key: WEEKLY_EDU_REPORT_TYPES.boys
  },
  {
    label: 'Nữ thanh niên',
    key: WEEKLY_EDU_REPORT_TYPES.girls
  },
]

export const CURRENT_STATUS_REPORT_FIELDS = [
  {
    label: 'Chiên đang học',
    key: CURRENT_STATUS_REPORT_TYPES.educating_sheep
  },
  {
    label: 'SWAT hoạt động',
    key: CURRENT_STATUS_REPORT_TYPES.active_swat
  },
  {
    label: 'GSKT hoạt động',
    key: CURRENT_STATUS_REPORT_TYPES.active_gskt
  },
  {
    label: '1L dưới 13 tuổi',
    key: CURRENT_STATUS_REPORT_TYPES.child_1l
  },
]

export const DAILY_REPORT_FIELDS = [
  {
    label: 'ĐT',
    key: REPORT_TYPES.DT
  },
  {
    label: 'HH',
    key: REPORT_TYPES.HH
  },
  {
    label: 'BT>HS',
    key: REPORT_TYPES.BT_HS
  },
  {
    label: 'BT',
    key: REPORT_TYPES.BT
  },
  {
    label: 'TP 1L>HS',
    key: REPORT_TYPES.TP_1L_HS
  },
  {
    label: 'TP 1L',
    key: REPORT_TYPES.TP_1L
  },
  {
    label: 'TP 4L>HS',
    key: REPORT_TYPES.TP_4L_HS
  },
  {
    label: 'TP 4L',
    key: REPORT_TYPES.TP_4L
  },
  {
    label: 'MCXD',
    key: REPORT_TYPES.MCXD
  },
  {
    label: 'Laxaro>HS',
    key: REPORT_TYPES.Laxaro_HS
  },
  {
    label: 'Laxaro',
    key: REPORT_TYPES.Laxaro
  },
]

export const EIGHT_STEPS_REPORT_FIELDS = [
  {
    label: 'Bước 1',
    key: EIGHT_STEPS_REPORT_TYPES.step_1
  },
  {
    label: 'Bước 2',
    key: EIGHT_STEPS_REPORT_TYPES.step_2
  },
  {
    label: 'Bước 3',
    key: EIGHT_STEPS_REPORT_TYPES.step_3
  },
  {
    label: 'Bước 4',
    key: EIGHT_STEPS_REPORT_TYPES.step_4
  },
  {
    label: 'Bước 5',
    key: EIGHT_STEPS_REPORT_TYPES.step_5
  },
  {
    label: 'Bước 6',
    key: EIGHT_STEPS_REPORT_TYPES.step_6
  },
  {
    label: 'Bước 7',
    key: EIGHT_STEPS_REPORT_TYPES.step_7
  },
  {
    label: 'Bước 8',
    key: EIGHT_STEPS_REPORT_TYPES.step_8
  },
]

export const WEEKLY_GENERAL_REPORT_FIELDS = [
  {
    label: 'BT',
    key: WEEKLY_GENERAL_REPORT_TYPES.BT
  },
  {
    label: '1L~3L',
    key: WEEKLY_GENERAL_REPORT_TYPES["1L_3L"]
  },
  {
    label: '>4L',
    key: WEEKLY_GENERAL_REPORT_TYPES["4L"]
  },
  {
    label: 'GSKT',
    key: WEEKLY_GENERAL_REPORT_TYPES.GSKT
  },
  {
    label: 'Ng truđô > 8 số',
    key: WEEKLY_GENERAL_REPORT_TYPES.NTD_8_plus
  },
  {
    label: 'Ng truđô < 8 số',
    key: WEEKLY_GENERAL_REPORT_TYPES.NTD_8_minus
  },
  {
    label: '1L dưới 13 tuổi',
    key: WEEKLY_GENERAL_REPORT_TYPES.child_1l
  },
  {
    label: 'Chiên đang học',
    key: WEEKLY_GENERAL_REPORT_TYPES.educating_sheep
  },
  {
    label: 'GSKT hoạt động',
    key: WEEKLY_GENERAL_REPORT_TYPES.active_gskt
  },
  {
    label: 'SWAT hoạt động',
    key: WEEKLY_GENERAL_REPORT_TYPES.active_swat
  },
  {
    label: 'KVT Nam',
    key: WEEKLY_GENERAL_REPORT_TYPES.KVT_male
  },
  {
    label: 'KVT Nữ',
    key: WEEKLY_GENERAL_REPORT_TYPES.KVT_female
  },
  {
    label: 'WM',
    key: WEEKLY_GENERAL_REPORT_TYPES.WM
  },
]

export const MONTHLY_GENERAL_REPORT_FIELDS = [
  // Trudo
  {
    label: 'ĐT',
    key: MONTHLY_GENERAL_REPORT_TYPES.DT
  },
  {
    label: 'HH',
    key: MONTHLY_GENERAL_REPORT_TYPES.HH
  },
  {
    label: 'BT',
    key: MONTHLY_GENERAL_REPORT_TYPES.BT
  },
  {
    label: 'Điểm danh',
    key: MONTHLY_GENERAL_REPORT_TYPES.attendance
  },
  {
    label: 'Menchixedec',
    key: MONTHLY_GENERAL_REPORT_TYPES.menchixedec
  },

  // TP
  {
    label: '1lần~3lần',
    key: MONTHLY_GENERAL_REPORT_TYPES["1L_3L"]
  },
  {
    label: '4lần<',
    key: MONTHLY_GENERAL_REPORT_TYPES["4L"]
  },
  {
    label: 'MCXD',
    key: MONTHLY_GENERAL_REPORT_TYPES.MCXD
  },

  // Giao duc
  {
    label: 'Thánh đồ mới',
    key: MONTHLY_GENERAL_REPORT_TYPES.new_member
  },
  {
    label: 'Thánh đồ 1',
    key: MONTHLY_GENERAL_REPORT_TYPES.member_1
  },
  {
    label: 'Thánh đồ 2',
    key: MONTHLY_GENERAL_REPORT_TYPES.member_2
  },
  {
    label: 'NTĐ',
    key: MONTHLY_GENERAL_REPORT_TYPES.NTD_edu
  },
  {
    label: 'Chức trách',
    key: MONTHLY_GENERAL_REPORT_TYPES.role_member
  },

  // NGV
  {
    label: 'NTĐ',
    key: MONTHLY_GENERAL_REPORT_TYPES.NTD_NGV
  },
  {
    label: 'KVT (tạm thời)',
    key: MONTHLY_GENERAL_REPORT_TYPES.KVT_temp
  },
  {
    label: 'KVT (chính thức)',
    key: MONTHLY_GENERAL_REPORT_TYPES.KVT_official
  },
  {
    label: 'ĐVT (tạm thời)',
    key: MONTHLY_GENERAL_REPORT_TYPES.DVT_temp
  },
  {
    label: 'ĐVT (chính thức)',
    key: MONTHLY_GENERAL_REPORT_TYPES.DVT_official
  },
  {
    label: 'NQLNTP',
    key: MONTHLY_GENERAL_REPORT_TYPES.NQLNTP
  },
  {
    label: 'NQLNCH',
    key: MONTHLY_GENERAL_REPORT_TYPES.NQLNCH
  },

  // Phan tach
  {
    label: 'IUBA',
    key: MONTHLY_GENERAL_REPORT_TYPES.IUBA
  },
  {
    label: 'WM',
    key: MONTHLY_GENERAL_REPORT_TYPES.WM
  },
  {
    label: 'SION',
    key: MONTHLY_GENERAL_REPORT_TYPES.SION
  },
  {
    label: 'CHI HỘI',
    key: MONTHLY_GENERAL_REPORT_TYPES.branch
  },
]

export const REPORT_FOR = {
  BRANCH: 'branch',
  CURRENT: 'current',
}

export const WM_TYPES = {
  total: 'total',
  HSCN: 'HSCN',
  HSCN_english: 'HSCN_english',
  HSTL: 'HSTL',
  HSTL_english: 'HSTL_english',
  PTGD: 'PTGD',
  PTGD_english: 'PTGD_english',
  minus_time: 'minus_time'
}

export const WM_FIELDS = [
  {
    label: 'Tổng điểm',
    key: WM_TYPES.total
  },
  {
    label: 'HSCN',
    key: WM_TYPES.HSCN
  },
  {
    label: 'HSTL',
    key: WM_TYPES.HSTL
  },
  {
    label: 'PTGD',
    key: WM_TYPES.PTGD
  },
  {
    label: 'HSCN (english)',
    key: WM_TYPES.HSCN_english
  },
  {
    label: 'HSTL (english)',
    key: WM_TYPES.HSTL_english
  },
  {
    label: 'PTGD (english)',
    key: WM_TYPES.PTGD_english
  },
  {
    label: 'Trừ điểm (lần)',
    key: WM_TYPES.minus_time
  },
]

/**
 * Leaderboard (Thi đua)
 */
export const INPUT_TAB_VALUES = {
  branch: 'branch',
  group: 'area',
  individual: 'member',
}

export const INPUT_TABS = [
  {
    label: 'Chi hội',
    id: INPUT_TAB_VALUES.branch
  },
  {
    label: 'Địa vực',
    id: INPUT_TAB_VALUES.group
  },
  {
    label: 'Cá nhân',
    id: INPUT_TAB_VALUES.individual
  },
]

export const LEADERBOARD_BRANCH_TYPES = {
  name: 'branch_name',
  tp_4l: 'tp_4L',
  total_point: 'total_point',
}

export const LEADERBOARD_GROUP_TYPES = {
  name: 'area_name',
  branch_uuid: 'branch_uuid',
  tp_4l: 'tp_4L',
  total_point: 'total_point',
}

export const LEADERBOARD_INDIVIDUAL_TYPES = {
  person_name: 'member_name',
  group_uuid: 'area_uuid',
  total_point: 'total_point',
  is_completed: 'is_completed',
}

export const LEADERBOARD_BRANCH_FIELDS = [
  {
    label: 'Tên Chi Hội',
    key: LEADERBOARD_BRANCH_TYPES.name,
    type: 'text',
  },
  {
    label: 'TP 4L',
    key: LEADERBOARD_BRANCH_TYPES.tp_4l,
    type: 'number',
    readOnly: true,
  },
  {
    label: 'Tổng điểm',
    key: LEADERBOARD_BRANCH_TYPES.total_point,
    type: 'number',
    readOnly: true,
  },
]

export const LEADERBOARD_GROUP_FIELDS = [
  {
    label: 'Tên Địa Vực',
    key: LEADERBOARD_GROUP_TYPES.name,
    type: 'text',
  },
  {
    label: 'Tên Chi Hội',
    key: LEADERBOARD_GROUP_TYPES.branch_uuid,
    type: 'text',
  },
  {
    label: 'TP 4L',
    key: LEADERBOARD_GROUP_TYPES.tp_4l,
    type: 'number',
  },
  {
    label: 'Tổng điểm',
    key: LEADERBOARD_GROUP_TYPES.total_point,
    type: 'number',
  },
]

export const LEADERBOARD_INDIVIDUAL_FIELDS = [
  {
    label: 'Họ tên ace',
    key: LEADERBOARD_INDIVIDUAL_TYPES.person_name,
    type: 'text',
  },
  {
    label: 'Tên Địa Vực',
    key: LEADERBOARD_INDIVIDUAL_TYPES.group_uuid,
    type: 'text',
  },
  {
    label: 'Tổng điểm',
    key: LEADERBOARD_INDIVIDUAL_TYPES.total_point,
    type: 'number',
  },
  {
    label: 'Đã hoàn thành',
    key: LEADERBOARD_INDIVIDUAL_TYPES.is_completed,
    type: 'checkbox',
  },
]

export const LEADERBOARD_AGE_TYPES = {
  men: 'men',
  women: 'women',
  young_male: 'young_male',
  young_female: 'young_female',
  student_male: 'student_male',
  student_female: 'student_female',
}

export const LEADERBOARD_AGES = [
  {
    label: 'Tráng niên',
    id: LEADERBOARD_AGE_TYPES.men,
  },
  {
    label: 'Phụ nữ',
    id: LEADERBOARD_AGE_TYPES.women,
  },
  {
    label: 'Thanh niên nam',
    id: LEADERBOARD_AGE_TYPES.young_male,
  },
  {
    label: 'Thanh niên nữ',
    id: LEADERBOARD_AGE_TYPES.young_female,
  },
  {
    label: 'Học sinh nam',
    id: LEADERBOARD_AGE_TYPES.student_male,
  },
  {
    label: 'Học sinh nữ',
    id: LEADERBOARD_AGE_TYPES.student_female,
  },
]

export const LEADERBOARD_RANKED_KEY = 'rankedPoint'