import Ajax from './ajax'

class UserAjax extends Ajax {
    list(uriTail, loading = true) {
        return this.ajax(loading).get(`/users${uriTail ? uriTail : ''}`)
    }

    create(data) {
        return this.ajax().post(`/users`, data)
    }

    update(id, data) {
        return this.ajax().post(`/users/${id}/update`, data)
    }

    listManager(uriTail) {
        return this.ajax().get(`/users-manager${uriTail ? uriTail : ''}`)
    }

    validateUser(uriTail) {
        return this.ajax().get(`/validate-user${uriTail ? uriTail : ''}`)
    }
}

const userAjax = new UserAjax()
export default userAjax
