import React, { useState } from "react";
import { useSelector } from "react-redux";

import InputData from "./components/input-data/InputData";
import Leaderboard from "./components/leaderboard/Leaderboard";

import userConstant from "../../constant/user";

import './CompetitionPage.scss';

const TAB_VALUES = {
  bxh: 'bxh',
  input: 'input',
}

const TABS = [
  {
    label: 'BXH',
    id: TAB_VALUES.bxh
  },
  {
    label: 'Nhập số liệu',
    id: TAB_VALUES.input
  }
]

const allowedUserIds = [
  35, // kvt Huy
  2, // csn Kim
  25, // Miso
  12, // Giacop
  6, // Ysac
  17, // Phuong Dong
]

const CompetitionPage = () => {
  const { userAuth } = useSelector(state => state)
  const [currentTab, setCurrentTab] = useState(TABS[0])

  return (
    <div className='competitionPage'>
      <h3 className="font-weight-bold">Thi Đua</h3>
      {(userAuth.roles === userConstant.ROLE_VALUES.HT || allowedUserIds.includes(userAuth.user_id)) && (
        <ul className="nav nav-tabs">
          {TABS.map(tab => (
            <li key={tab.id} className="nav-item" onClick={() => setCurrentTab(tab)}>
              <div className={"nav-link " + (currentTab.id === tab.id && 'active')}>{tab.label}</div>
            </li>
          ))}
        </ul>
      )}

      {currentTab.id === TAB_VALUES.bxh && <Leaderboard />}
      {currentTab.id === TAB_VALUES.input && <InputData />}
    </div>
  )
}

export default CompetitionPage