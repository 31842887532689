import moment from 'moment';
import Pusher from 'pusher-js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { toast } from 'react-toastify';

import reportAjax from '@ajax/report';
import apiResult from '@util/apiResult';
import { handleError } from '@util/common';
import { CURRENT_STATUS_REPORT_FIELDS, CURRENT_STATUS_REPORT_TYPES, DAILY_REPORT_FIELDS, EIGHT_STEPS_REPORT_FIELDS, EIGHT_STEPS_REPORT_TYPES, MONTHLY_GENERAL_REPORT_FIELDS, MONTHLY_GENERAL_REPORT_TYPES, MONTHLY_GENERAL_REPORT_TYPES_SHORT, REPORT_RANGES, REPORT_TYPES, WEEKLY_EDU_REPORT_FIELDS, WEEKLY_EDU_REPORT_TYPES, WEEKLY_GENERAL_REPORT_FIELDS, WEEKLY_GENERAL_REPORT_TYPES, WEEKLY_GENERAL_REPORT_TYPES_SHORT } from '../../constant/report';
import { CHANNELS, EVENTS, PUSHER_KEY } from '../../constant/socket';

import userConstant from '../../constant/user';
import "./ReportAdd.scss";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const ReportAdd = ({ selectedReport, date, reportType, fullDate, label }) => {
  const isCurrentStatusReport = reportType === REPORT_RANGES.CURRENT_STATUS
  const isGeneralReport = (reportType === REPORT_RANGES.MONTHLY_GENERAL) || (reportType === REPORT_RANGES.WEEKLY_GENERAL)
  // const isWeeklyGeneralReport = reportType === REPORT_RANGES.WEEKLY_GENERAL
  const isMonthlyGeneralReport = reportType === REPORT_RANGES.MONTHLY_GENERAL

  const numberFormatter = Intl.NumberFormat('en-US');

  const userAuth = useSelector(state => state.userAuth)
  const [report, setReport] = useState(selectedReport || {})
  const [subReports, setSubReports] = useState([])

  const handleChangeField = (value, key) => {
    setReport(prevState => ({ ...prevState, [key]: value }))
  }

  const handleSuccess = (res) => {
    const data = apiResult.success(res)
    setReport(data)
    toast.success(
      data.is_submitted ? 'Đã hoàn thành' : 'Đã lưu',
    );
  }

  const getReportId = () => {
    return report?.report_id
      || report?.weekly_edu_report_id
      || report?.weekly_general_report_id
      || report?.monthly_general_report_id
      || report?.current_status_id
      || report?.eight_step_report_id
  }

  const isTimeValidToSubmit = () => {
    if (isMonthlyGeneralReport) return true
    // if (!isMonthlyGeneralReport && !isWeeklyGeneralReport) {
    //   return true
    // }

    const now = moment()
    const submitDeadline = isMonthlyGeneralReport
      // ? moment(fullDate.endDate).add('days', 2).set('hour', 0).set('minute', 0)
      ? moment(fullDate.endDate).add('days', 4).set('hour', 0).set('minute', 0)
      : moment(fullDate.endDate).add('days', 3).set('hour', 12).set('minute', 0)

    return now.isBefore(submitDeadline)
  }

  const handleSubmit = (isFinished, e) => {
    const isValidToSubmit = isTimeValidToSubmit()

    if (!isValidToSubmit) {
      handleError("Đã hết hạn báo cáo")
      return
    }

    const id = getReportId()

    if (id) {
      reportAjax.update(id, JSON.stringify({
        ...report,
        is_submitted: !report.is_submitted ? (isFinished ? 1 : 0) : report.is_submitted,
        type: reportType
      }))
        .then(handleSuccess)
        .catch(handleError)
    } else {
      reportAjax.create(JSON.stringify({
        ...report,
        is_submitted: isFinished ? 1 : 0,
        created_at: `${date} ${moment().format('HH:mm:ss')}`,
        report_date: `${date} ${moment().format('HH:mm:ss')}`,
        type: reportType
      }))
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  const getReportFields = () => {
    switch (reportType) {
      case REPORT_RANGES.DAILY:
        return DAILY_REPORT_FIELDS
      case REPORT_RANGES.EIGHT_STEPS:
        return EIGHT_STEPS_REPORT_FIELDS
      case REPORT_RANGES.WEEKLY_EDU:
        return WEEKLY_EDU_REPORT_FIELDS
      case REPORT_RANGES.WEEKLY_GENERAL:
        return WEEKLY_GENERAL_REPORT_FIELDS
      case REPORT_RANGES.MONTHLY_GENERAL:
        return MONTHLY_GENERAL_REPORT_FIELDS
      case REPORT_RANGES.CURRENT_STATUS:
        return CURRENT_STATUS_REPORT_FIELDS
      default:
        return null
    }
  }

  const getReportKeys = () => {
    switch (reportType) {
      case REPORT_RANGES.DAILY:
        return REPORT_TYPES
      case REPORT_RANGES.EIGHT_STEPS:
        return EIGHT_STEPS_REPORT_TYPES
      case REPORT_RANGES.WEEKLY_EDU:
        return WEEKLY_EDU_REPORT_TYPES
      case REPORT_RANGES.WEEKLY_GENERAL:
        return WEEKLY_GENERAL_REPORT_TYPES
      case REPORT_RANGES.MONTHLY_GENERAL:
        return MONTHLY_GENERAL_REPORT_TYPES
      case REPORT_RANGES.CURRENT_STATUS:
        return CURRENT_STATUS_REPORT_TYPES
      default:
        return null
    }
  }

  const innerColumns = Object.keys(REPORT_TYPES)
    .map((type, index) =>
    ({
      Header: DAILY_REPORT_FIELDS.find(field => field.key === type)?.label,
      accessor: REPORT_TYPES[type],
      width: 120,
      style: {
        textAlign: 'center'
      },
      Cell: props => props.value && props.value > 0
        ? <div className='has-value'>{numberFormatter.format(props.value)}</div>
        : <div className='no-value'>0</div>,
      Footer: (row) => {
        const sum = row.data
          .map(data => parseInt(data[REPORT_TYPES[type]]))
          .reduce((a, b) => a + b, 0);

        return sum && sum > 0 ? <div className='has-value'>{numberFormatter.format(sum)}</div> : 0
      }
    }))

  const innerColumnsEightSteps = Object.keys(EIGHT_STEPS_REPORT_TYPES)
    .map(type =>
    ({
      Header: EIGHT_STEPS_REPORT_FIELDS.find(field => field.key === type)?.label,
      accessor: EIGHT_STEPS_REPORT_TYPES[type],
      width: 120,
      style: {
        textAlign: 'center'
      },
      Cell: props => props.value && props.value > 0
        ? <div className='has-value'>{numberFormatter.format(props.value)}</div>
        : <div className='no-value'>0</div>,
      Footer: (row) => {
        const sum = row.data
          .map(data => parseInt(data[REPORT_TYPES[type]]))
          .reduce((a, b) => a + b, 0);

        return sum && sum > 0 ? <div className='has-value'>{numberFormatter.format(sum)}</div> : 0
      }
    }))

  const innerColumnsEdu = Object.keys(WEEKLY_EDU_REPORT_TYPES)
    .map((type, index) =>
    ({
      Header: WEEKLY_EDU_REPORT_FIELDS.find(field => field.key === type)?.label,
      accessor: WEEKLY_EDU_REPORT_TYPES[type],
      width: 140,
      style: {
        textAlign: 'center',
      },
      Cell: props => props.value && props.value > 0
        ? <div className='has-value'>{numberFormatter.format(props.value)}</div>
        : <div className='no-value'>0</div>,
      Footer: (row) => {
        const sum = row.data
          .map(data => parseInt(data[WEEKLY_EDU_REPORT_TYPES[type]]))
          .reduce((a, b) => a + b, 0);

        return sum && sum > 0 ? <div className='has-value'>{numberFormatter.format(sum)}</div> : 0
      }
    }))

  const innerColumnsWeeklyGeneral = Object.keys(WEEKLY_GENERAL_REPORT_TYPES_SHORT)
    .map((type, index) => {
      const label = WEEKLY_GENERAL_REPORT_FIELDS.find(field => field.key === type)?.label

      return ({
        Header: label,
        accessor: null,
        width: 140,
        style: {
          textAlign: 'center'
        },
        Cell: props => {
          const rawValue = props?.original
          const plannedValue = rawValue[type + "_plan"]
          const resultValue = rawValue[type]
          return <div className='multirow-value'>
            <div>Kế hoạch: <span className={plannedValue > 0 ? 'has-value' : 'no-value'}>
              {numberFormatter.format(plannedValue)}
            </span></div>
            <div>Kết quả: <span className={resultValue > 0 ? 'has-value' : 'no-value'}>
              {numberFormatter.format(resultValue)}
            </span></div>
          </div>
        },
        Footer: (row) => {
          const plannedValue = row.data
            .map(data => parseInt(data._original[WEEKLY_GENERAL_REPORT_TYPES_SHORT[type] + "_plan"]))
            .reduce((a, b) => a + b, 0)
            || 0;
          const resultValue = row.data
            .map(data => parseInt(data._original[WEEKLY_GENERAL_REPORT_TYPES_SHORT[type]]))
            .reduce((a, b) => a + b, 0)
            || 0;

          return <div className='multirow-value'>
            <div>Kế hoạch: <div style={{ display: 'inline-block' }} className={plannedValue > 0 ? 'has-value' : 'no-value'}>
              {numberFormatter.format(plannedValue)}
            </div></div>
            <div>Kết quả: <div style={{ display: 'inline-block' }} className={resultValue > 0 ? 'has-value' : 'no-value'}>
              {numberFormatter.format(resultValue)}
            </div></div>
          </div>
        }
      })
    }
    )

  const innerColumnsMonthlyGeneral = Object.keys(MONTHLY_GENERAL_REPORT_TYPES_SHORT)
    .map((type, index) => {
      const label = MONTHLY_GENERAL_REPORT_FIELDS.find(field => field.key === type)?.label

      return ({
        Header: label,
        accessor: null,
        width: 140,
        style: {
          textAlign: 'center'
        },
        Cell: props => {
          const rawValue = props?.original
          const plannedValue = rawValue[type + "_plan"]
          const resultValue = rawValue[type]
          return <div className='multirow-value'>
            <div>Kế hoạch: <span className={plannedValue > 0 ? 'has-value' : 'no-value'}>
              {numberFormatter.format(plannedValue)}
            </span></div>
            <div>Kết quả: <span className={resultValue > 0 ? 'has-value' : 'no-value'}>
              {numberFormatter.format(resultValue)}
            </span></div>
          </div>
        },
        Footer: (row) => {
          const plannedValue = row.data
            .map(data => parseInt(data._original[MONTHLY_GENERAL_REPORT_TYPES_SHORT[type] + "_plan"]))
            .reduce((a, b) => a + b, 0)
            || 0;
          const resultValue = row.data
            .map(data => parseInt(data._original[MONTHLY_GENERAL_REPORT_TYPES_SHORT[type]]))
            .reduce((a, b) => a + b, 0)
            || 0;

          return <div className='multirow-value'>
            <div>Kế hoạch: <div style={{ display: 'inline-block' }} className={plannedValue > 0 ? 'has-value' : 'no-value'}>
              {numberFormatter.format(plannedValue)}
            </div></div>
            <div>Kết quả: <div style={{ display: 'inline-block' }} className={resultValue > 0 ? 'has-value' : 'no-value'}>
              {numberFormatter.format(resultValue)}
            </div></div>
          </div>
        }
      })
    }
    )

  const getColumns = () => {
    switch (reportType) {
      case REPORT_RANGES.DAILY:
        return innerColumns
      case REPORT_RANGES.EIGHT_STEPS:
        return innerColumnsEightSteps
      case REPORT_RANGES.WEEKLY_EDU:
        return innerColumnsEdu
      case REPORT_RANGES.WEEKLY_GENERAL:
        return innerColumnsWeeklyGeneral
      case REPORT_RANGES.MONTHLY_GENERAL:
        return innerColumnsMonthlyGeneral
      default:
        return null
    }
  }

  const updateNewContent = () => {
    const reportTotal = {}
    getReportKeys(reportType) && Object.keys(getReportKeys(reportType)).forEach((type, index) => {
      reportTotal[type] = 0

      for (let i = 0; i < subReports.length; i++) {
        const report = subReports?.[i].report
        reportTotal[type] += parseInt(report[type])
      }

      return type
    })

    setReport({ ...report, ...reportTotal })
    toast.success("Đã cập nhật số liệu báo cáo")
  }

  const getTotalSub = () => {
    reportAjax.list(`?tab=one&date=${date || ''}&range=${reportType || ''}`)
      .then(res => {
        const tempSubReports = apiResult.success(res)
        setSubReports(tempSubReports)
      })
      .catch(handleError)
  }

  const displayTimeTag = (props) => {
    return <div>{props.value}
      <br />
      <small>
        {props?.original?.is_submitted
          ? <span style={{ color: 'green' }}>Đã hoàn thành</span>
          : props?.original?.updated_at
            ? <span style={{ color: 'navy' }}>Đã lưu tạm thời</span>
            : <span style={{ color: 'red' }}>Chưa báo cáo</span>
        }
      </small>
      <br />
      {(props?.original?.is_submitted || props?.original?.updated_at) && <small>
        &nbsp;&nbsp;({moment(props?.original?.updated_at).format('MM/DD, HH:mm')})
      </small>}
    </div>
  }

  const getFixedColumns = () => {
    return [
      {
        Header: "Khu vực",
        accessor: 'zion',
        width: 110,
        Footer: "TỔNG",
        Cell: props => {
          return <div className={
            Object.keys(props.original)
              .filter(key => key !== 'date')
              .map(key => props.original[key])
              .filter(value => parseInt(value) > 0).length > 0
              ? 'has-value' : 'no-value'
          }>{displayTimeTag(props)}</div>
        }
      },
    ]
  }

  const handleEvent = () => {
    getTotalSub()
  }

  useEffect(() => {
    if (reportType && !isCurrentStatusReport) {
      reportAjax.checkSubmission(`?id=${getReportId()}&type=${reportType}`)
        .then(res => {
          const resReport = apiResult.success(res)
          setReport(resReport)

          if (userAuth.has_user_level) {
            reportAjax.list(`?tab=one&date=${date || ''}&range=${reportType || ''}`)
              .then(res => {
                const tempSubReports = apiResult.success(res)
                setSubReports(tempSubReports)

                if (!resReport) {
                  const reportTotal = {}
                  getReportKeys(reportType) && Object.keys(getReportKeys(reportType)).forEach(type => {
                    reportTotal[type] = 0

                    for (let i = 0; i < tempSubReports?.length; i++) {
                      const report = tempSubReports?.[i].report
                      reportTotal[type] += parseInt(report[type])
                    }

                    return type
                  })

                  setReport(reportTotal)
                }
              })
              .catch(handleError)
          }
        })
        .catch(handleError)
    }

    if (userAuth.has_user_level) {
      const pusher = new Pusher(PUSHER_KEY, {
        cluster: 'ap1'
      });

      const channel = pusher.subscribe(CHANNELS.SUMMARY);
      channel.bind(EVENTS.UPDATE, handleEvent);

      return () => {
        channel.unsubscribe(CHANNELS.SUMMARY)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tableData = subReports.map(reportRow => ({ zion: reportRow.zion, ...reportRow.report }))

  return <div className={`reportAdd ${reportType}`}>
    <h6 className='text-center'>
      {reportType === REPORT_RANGES.MONTHLY_GENERAL
        ? date.substr(0, 7)
        : reportType === REPORT_RANGES.WEEKLY_EDU
          ? <>{label}</>
          : reportType === REPORT_RANGES.WEEKLY_GENERAL && fullDate
            ? <>{fullDate.label} <small>({fullDate.startDate.format('MM/DD')} - {fullDate.endDate.format('MM/DD')})</small></>
            : date}
    </h6>

    {userAuth.roles !== userConstant.ROLE_VALUES.HT && userAuth.has_user_level && subReports.length > 0 &&
      <div className='summary-table'>
        <ReactTableFixedColumns
          key={reportType}
          data={tableData}
          columns={[
            {
              Header: "",
              fixed: "left",
              columns: getFixedColumns()
            },
            {
              Header: "",
              columns: getColumns()
            },
          ]}
          defaultPageSize={10}
          style={{ height: "60vh", minHeight: 350 }}
          className="-striped"
          previousText={"< Trước"}
          nextText={"Sau >"}
          loadingText="Đang tải..."
          rowsText='dòng/trang'
          pageText="Trang"
          ofText="/"
        />
        <hr />
      </div>}

    {userAuth.roles !== userConstant.ROLE_VALUES.HT && userAuth.has_user_level && subReports.length > 0 && <div className='pull-data-row'>
      <div className='pull-data-btn' onClick={updateNewContent}>Nạp dữ liệu mới nhất <i className='fa fa-arrow-down' /></div>
    </div>}

    <ol>
      {getReportFields()?.map((field, index) =>
        <li key={field.label + index}>
          {isGeneralReport
            ? <div className='row-multi-fields'>
              <div className='row-title'>{field.label}</div>
              <div className='row-fields'>
                <div className='report-input-field'>
                  <label htmlFor={`${field.key}_plan`}>Kế hoạch:</label> <input
                    id={`${field.key}_plan`}
                    placeholder=''
                    type="number"
                    onChange={(e) => handleChangeField(e.target.value, `${field.key}_plan`)}
                    value={report?.[`${field.key}_plan`] || ''}
                  />
                </div>
                <div className='report-input-field'>
                  <label htmlFor={field.key}>Kết quả:</label> <input
                    id={field.key}
                    placeholder=''
                    type="number"
                    onChange={(e) => handleChangeField(e.target.value, field.key)}
                    value={report?.[field.key] || ''}
                  />
                </div>
              </div>
            </div>
            : <>
              <label htmlFor={field.key}>{field.label}:</label> <input
                id={field.key}
                placeholder=''
                type="number"
                onChange={(e) => handleChangeField(e.target.value, field.key)}
                value={report?.[field.key] || ''}
              />
            </>}
        </li>
      )}
    </ol>
    <div className='bottom-pad'>
      {!isCurrentStatusReport &&
        <div className={report?.is_submitted ? 'disabled' : ''} onClick={report?.is_submitted ? () => { } : () => handleSubmit(false)}>Lưu tạm thời</div>
      }
      <div style={isCurrentStatusReport ? { width: '100%' } : {}} onClick={() => handleSubmit(true)}>Đã hoàn thành</div>
    </div>
  </div>
}

export default ReportAdd;