import Pusher from 'pusher-js';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import apiResult from '@util/apiResult';
import { handleError } from '@util/common';

import { INPUT_TABS, INPUT_TAB_VALUES, LEADERBOARD_AGES, LEADERBOARD_BRANCH_TYPES, LEADERBOARD_GROUP_TYPES, LEADERBOARD_INDIVIDUAL_TYPES, LEADERBOARD_RANKED_KEY } from "../../../../constant/report";

import competitionAjax from "../../../../ajax/competition";
import { CHANNELS, EVENTS, PUSHER_KEY } from "../../../../constant/socket";

import './Leaderboard.scss';

const Leaderboard = () => {
  const { userAuth } = useSelector(state => state)

  const [currentTab, setCurrentTab] = useState(INPUT_TABS[0])
  const [currentAge, setCurrentAge] = useState(LEADERBOARD_AGES[0].id)
  const [rows, setRows] = useState([])
  const [ranks, setRanks] = useState([])

  const [branches, setBranches] = useState([])
  const [areas, setAreas] = useState([])
  const [members, setMembers] = useState([])

  const [dataNeedUpdate, setDataNeedUpdate] = useState(0)

  const isIndividualType = currentTab.id === INPUT_TAB_VALUES.individual
  const isBranchType = currentTab.id === INPUT_TAB_VALUES.branch

  const handleChangeTab = (tab) => {
    setCurrentAge(LEADERBOARD_AGES[0].id)
    setCurrentTab(tab)
  }

  const handleChangeAge = (age) => {
    setCurrentAge(age)
  }

  const displayLeaderboardHeading = () => {
    if (currentTab.id === INPUT_TAB_VALUES.branch) {
      return 'Chi Hội'
    }

    if (currentTab.id === INPUT_TAB_VALUES.group) {
      return `Địa vực - ${LEADERBOARD_AGES.find(age => age.id === currentAge)?.label}`
    }

    if (currentTab.id === INPUT_TAB_VALUES.individual) {
      return `Cá nhân - ${LEADERBOARD_AGES.find(age => age.id === currentAge)?.label}`
    }

    return ''
  }

  const displayPoint = (rankedRow) => {
    const numberFormatter = Intl.NumberFormat('en-US');

    const point = isIndividualType
      ? rankedRow[LEADERBOARD_INDIVIDUAL_TYPES.total_point]
      : rankedRow[LEADERBOARD_RANKED_KEY]

    const rawPoint = parseFloat(point)?.toFixed(2)
    return [numberFormatter.format(rawPoint.split('.')[0]), rawPoint.split('.')[1]].join('.')
  }

  const handleEvent = (e) => {
    setDataNeedUpdate(prevState => prevState += 1)
  }

  useEffect(() => {
    competitionAjax.getCompetitionRows()
      .then(apiResponse => {
        const responses = apiResult.success(apiResponse)
        setBranches(responses.filter(item => item.type === 'branch'))
        setAreas(responses.filter(item => item.type === 'area'))
        setMembers(responses.filter(item => item.type === 'member'))
      })
      .catch(handleError)

    const pusher = new Pusher(PUSHER_KEY, {
      cluster: 'ap1'
    });

    const channel = pusher.subscribe(CHANNELS.LEADERBOARD);
    channel.bind(EVENTS.LEADERBOARD_UPDATE, handleEvent);

    return () => {
      channel.unsubscribe(CHANNELS.LEADERBOARD)
    }
  }, [dataNeedUpdate])

  useEffect(() => {
    if (userAuth?.user_id) {
      if (currentTab.id === INPUT_TAB_VALUES.branch) {
        setRows(branches)
      } else if (currentTab.id === INPUT_TAB_VALUES.group) {
        setRows(areas.filter(item => item.age_range === currentAge) || [])
      } else if (currentTab.id === INPUT_TAB_VALUES.individual) {
        setRows(members.filter(item => item.age_range === currentAge) || [])
      }
    }
  }, [userAuth, currentTab, currentAge, areas, branches, members])

  useEffect(() => {
    const rowWithPoint = isBranchType && areas
      ? rows.map(branch => {
        let totalPoint = 0
        let totalTp4L = 0
        let groups = []

        const validGroups = areas?.filter(group => group.branch_uuid === branch.uuid)
        groups.push(validGroups)
        totalPoint = totalPoint + validGroups.reduce((sum, { total_point }) => sum + parseFloat(total_point), 0) || 0
        totalTp4L = totalTp4L + validGroups.reduce((sum, { tp_4L }) => sum + parseFloat(tp_4L), 0) || 0

        return {
          ...branch,
          total_point: totalPoint,
          tp_4L: totalTp4L,
        }
      })
      : rows

    const validRows = rowWithPoint.filter(row => {
      return row[LEADERBOARD_BRANCH_TYPES.total_point] && (
        isIndividualType
          ? row[LEADERBOARD_INDIVIDUAL_TYPES.person_name]
          : isBranchType
            ? (row[LEADERBOARD_BRANCH_TYPES.name] && row[LEADERBOARD_BRANCH_TYPES.tp_4l])
            : (row[LEADERBOARD_GROUP_TYPES.name] && row[LEADERBOARD_GROUP_TYPES.tp_4l])
      )
    }
    )

    const calculatedRows = validRows.map(row => (
      {
        ...row,
        [LEADERBOARD_RANKED_KEY]: isIndividualType
          ? row[LEADERBOARD_BRANCH_TYPES.total_point]
          : row[LEADERBOARD_BRANCH_TYPES.total_point] / row[LEADERBOARD_BRANCH_TYPES.tp_4l]
      }
    ))

    calculatedRows.sort((rowA, rowB) => {
      return rowB[LEADERBOARD_RANKED_KEY] - rowA[LEADERBOARD_RANKED_KEY]
    })

    if (isIndividualType) {
      calculatedRows.sort((rowA, rowB) => {
        return rowB.is_completed - rowA.is_completed
      })
    }

    setRanks(calculatedRows)
  }, [rows, isIndividualType, isBranchType, branches, areas, members, currentTab])

  return (
    <div className="leaderboard-block">
      <ul className="nav nav-tabs">
        {INPUT_TABS.map(tab => (
          <li key={tab.id} className="nav-item" onClick={() => handleChangeTab(tab)}>
            <div className={"nav-link " + (currentTab.id === tab.id && ' active')}>{tab.label}</div>
          </li>
        ))}
      </ul>

      {currentTab.id !== INPUT_TAB_VALUES.branch && (
        <div className="age-select">
          Theo độ tuổi:
          <select onChange={e => handleChangeAge(e.target.value)} value={currentAge}>
            {LEADERBOARD_AGES.map(age => (
              <option key={age.id} value={age.id}>{age.label}</option>
            ))}
          </select>
        </div>
      )}
      <main>
        <div id='headerBoard'>
          <h6>[Top #10] {displayLeaderboardHeading()}</h6>
        </div>

        {ranks.length === 0 && <div className="no-data">Đang cập nhật...</div>}

        {ranks.length > 0 && <div id='leaderboard'>
          {/* <div className='ribbon'></div> */}
          <table>
            <tbody>
              {ranks.slice(0, 10).map((rankedRow, index) =>
                <tr key={rankedRow.competition_row_id}>
                  <td className='number'>
                    <div className='medal-box'>
                      {(index + 1) <= 3 && <img className={'gold-medal ' + (isIndividualType ? 'small' : '')} src={`/images/competition/${currentTab.id}_${index + 1}.png`} alt="medal" />}
                      {index + 1 > 3 && `${index + 1}`}
                    </div>
                  </td>
                  <td className='name'>
                    {isIndividualType
                      ? rankedRow[LEADERBOARD_INDIVIDUAL_TYPES.person_name]
                      : isBranchType
                        ? rankedRow[LEADERBOARD_BRANCH_TYPES.name]
                        : rankedRow[LEADERBOARD_GROUP_TYPES.name]
                    }
                  </td>
                  {isIndividualType && <td className='name'>
                    {areas.find(area => area.uuid === rankedRow[LEADERBOARD_INDIVIDUAL_TYPES.group_uuid])?.[LEADERBOARD_GROUP_TYPES.name]}
                  </td>}
                  <td className='points'>
                    {isIndividualType && rankedRow.is_completed ? <i className='icon-circle-check'></i> : null}
                    {displayPoint(rankedRow)}
                    {/* {index === 0 && <img className='gold-medal' src="https://github.com/malunaridev/Challenges-iCodeThis/blob/master/4-leaderboard/assets/gold-medal.png?raw=true" alt="gold medal" />} */}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>}
      </main>
    </div>
  )
}

export default Leaderboard