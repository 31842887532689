import debounce from "lodash/debounce";
import Pusher from 'pusher-js';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

import apiResult from '@util/apiResult';
import { handleError, handleSuccess } from '@util/common';

import competitionAjax from "../../../../ajax/competition";
import { INPUT_TABS, INPUT_TAB_VALUES, LEADERBOARD_AGES, LEADERBOARD_BRANCH_FIELDS, LEADERBOARD_BRANCH_TYPES, LEADERBOARD_GROUP_FIELDS, LEADERBOARD_GROUP_TYPES, LEADERBOARD_INDIVIDUAL_FIELDS, LEADERBOARD_INDIVIDUAL_TYPES } from "../../../../constant/report";

import { CHANNELS, EVENTS, PUSHER_KEY } from "../../../../constant/socket";
import './InputData.scss';

const InputData = () => {
  const { userAuth } = useSelector(state => state)

  const [currentTab, setCurrentTab] = useState(INPUT_TABS[0])
  const [currentAge, setCurrentAge] = useState(LEADERBOARD_AGES[0].id)

  const [branches, setBranches] = useState([])

  const [areas, setAreas] = useState([])
  const [members, setMembers] = useState([])
  const [editingRows, setEditingRows] = useState([])

  const isBranchType = currentTab.id === INPUT_TAB_VALUES.branch

  const getReportFields = () => {
    switch (currentTab.id) {
      case INPUT_TAB_VALUES.branch:
        return LEADERBOARD_BRANCH_FIELDS
      case INPUT_TAB_VALUES.group:
        return LEADERBOARD_GROUP_FIELDS
      case INPUT_TAB_VALUES.individual:
        return LEADERBOARD_INDIVIDUAL_FIELDS
      default:
        return null
    }
  }

  const debounceSubmit = useCallback(debounce((rowId, key, value) => {
    handleSubmitRow(rowId, key, value)
  }, 1000), [])

  const handleChangeField = (value, fieldKey, row) => {
    const validValue = fieldKey === LEADERBOARD_BRANCH_TYPES.total_point || fieldKey === LEADERBOARD_BRANCH_TYPES.tp_4l
      ? parseFloat(value)
      : value

    debounceSubmit(row.competition_row_id, fieldKey, validValue)
  }

  const handleAddRow = () => {
    const uuid = uuidv4()

    competitionAjax.createCompetitionRow(JSON.stringify({
      uuid,
      type: currentTab.id,
      age_range: isBranchType ? '' : currentAge
    }))
      .then(res => {
        const row = apiResult.success(res)

        if (currentTab.id === INPUT_TAB_VALUES.branch) setBranches(branches => [...branches, row])
        if (currentTab.id === INPUT_TAB_VALUES.group) setAreas(areas => [...areas, row])
        if (currentTab.id === INPUT_TAB_VALUES.individual) setMembers(members => [...members, row])

        setTimeout(() => {
          document.getElementById(uuid).scrollIntoView({ behavior: 'smooth' })
        }, 500)
      })
      .catch(handleError)
  }

  const confirmDeleteRow = (row) => {
    if (window.confirm('Xác nhận xóa hàng này?')) handleDeleteRow(row)
  }

  const handleDeleteRow = (row) => {
    competitionAjax.deleteCompetitionRow(row.competition_row_id)
      .catch(handleError)
  }

  const handleChangeAge = (value) => {
    setCurrentAge(value)
  }

  const handleChangeTab = (tab) => {
    setCurrentAge(LEADERBOARD_AGES[0].id)
    setCurrentTab(tab)
  }

  const handleChangeBranch = (branchId, groupRow) => {
    const branch = branches.find(item => item.uuid === branchId)

    if (branch) {
      handleSubmitRow(groupRow.competition_row_id, LEADERBOARD_GROUP_TYPES.branch_uuid, branch.uuid)
    }
  }

  const handleChangeGroup = (groupId, groupRow) => {
    const group = areas.find(item => item.uuid === groupId)

    if (group) {
      handleSubmitRow(groupRow.competition_row_id, LEADERBOARD_INDIVIDUAL_TYPES.group_uuid, group.uuid)
    }
  }

  const getLatestRows = (rowType) => {
    competitionAjax.getCompetitionRows()
      .then(apiResponse => {
        const responses = apiResult.success(apiResponse)
        if (rowType && rowType === INPUT_TAB_VALUES.branch) setBranches([...responses].filter(item => item.type === INPUT_TAB_VALUES.branch))
        if (rowType && rowType === INPUT_TAB_VALUES.group) setAreas([...responses].filter(item => item.type === INPUT_TAB_VALUES.group))
        if (rowType && rowType === INPUT_TAB_VALUES.individual) setMembers([...responses].filter(item => item.type === INPUT_TAB_VALUES.individual))

        if (!rowType) {
          setBranches([...responses].filter(item => item.type === INPUT_TAB_VALUES.branch))
          setAreas([...responses].filter(item => item.type === INPUT_TAB_VALUES.group))
          setMembers([...responses].filter(item => item.type === INPUT_TAB_VALUES.individual))
        }
      })
      .catch(handleError)
  }

  const handleSubmitRow = (rowId, key, value) => {
    competitionAjax.updateCompetitionRow(rowId, JSON.stringify({ key, value }))
      .then(respsone => handleSuccess('Cập nhật thành công!'))
      .catch(handleError)
  }

  const setRowEditing = (row, isEditing) => {
    competitionAjax.editingCompetitionRow(row.competition_row_id, JSON.stringify({
      isEditing,
      row: { ...row, userName: userAuth.zion, userId: userAuth.user_id },
    }))
  }

  useEffect(() => {
    getLatestRows()

    const pusher = new Pusher(PUSHER_KEY, {
      cluster: 'ap1'
    });

    const handleEventRowEditing = (event) => {
      const row = event.message.row
      const isEditing = event.message.isEditing

      if (!row?.competition_row_id) return

      if (isEditing) {
        const existingRow = editingRows.find(editingRow => editingRow.competition_row_id === row.competition_row_id)
        if (!existingRow) {
          setEditingRows(editingRows => [...editingRows, row])
        }
      } else {
        setEditingRows(editingRows => [...editingRows.filter(item => item.competition_row_id !== row.competition_row_id)])
      }
    }

    const handleEventRowUpdated = (event) => {
      const row = event.message.row
      if (!row?.competition_row_id) return

      if (row.type === INPUT_TAB_VALUES.branch) {
        setBranches(prevBranches => prevBranches.map(branch => branch.uuid === row.uuid ? row : branch))
      }
      if (row.type === INPUT_TAB_VALUES.group) {
        setAreas(prevAreas => prevAreas.map(area => area.uuid === row.uuid ? row : area))
      }
      if (row.type === INPUT_TAB_VALUES.individual) {
        setMembers(prevMembers => prevMembers.map(member => member.uuid === row.uuid ? row : member))
      }
    }

    const handleEventRowDeleted = (event) => {
      const row = event.message.row
      if (!row?.competition_row_id) return

      if (row.type === INPUT_TAB_VALUES.branch) {
        setBranches(prevBranches => prevBranches.filter(branch => branch.uuid !== row.uuid))
      }
      if (row.type === INPUT_TAB_VALUES.group) {
        setAreas(prevAreas => prevAreas.filter(area => area.uuid !== row.uuid))
      }
      if (row.type === INPUT_TAB_VALUES.individual) {
        setMembers(prevMembers => prevMembers.filter(member => member.uuid !== row.uuid))
      }
    }

    const handleEventNewRow = (event) => {
      const row = event.message.row

      if (!row) return
      getLatestRows()
    }

    const channel = pusher.subscribe(CHANNELS.COMPETITION);
    channel.bind(EVENTS.EVENT_ROW_EDITING, handleEventRowEditing);
    channel.bind(EVENTS.EVENT_NEW_ROW, handleEventNewRow);
    channel.bind(EVENTS.EVENT_ROW_UPDATED, handleEventRowUpdated);
    channel.bind(EVENTS.EVENT_ROW_DELETED, handleEventRowDeleted);

    return () => {
      channel.unsubscribe(CHANNELS.COMPETITION)
    }
    // eslint-disable-next-line
  }, [])

  const { rowWithPoints } = useMemo(() => {
    const rowsByTab = currentTab.id === INPUT_TAB_VALUES.branch
      ? branches
      : currentTab.id === INPUT_TAB_VALUES.group
        ? [...areas.filter(area => area.age_range === currentAge)]
        : [...members.filter(area => area.age_range === currentAge)]

    const rowWithValue = isBranchType && areas
      ? rowsByTab.map(branchRow => {
        let totalPoint = 0
        let totalTp4L = 0
        let groups = []

        const validGroups = areas?.filter(group => group.branch_uuid === branchRow.uuid)
        groups.push(validGroups)
        totalPoint = totalPoint + validGroups.reduce((sum, { total_point }) => sum + parseFloat(total_point), 0) || 0
        totalTp4L = totalTp4L + validGroups.reduce((sum, { tp_4L }) => sum + parseFloat(tp_4L), 0) || 0

        return {
          ...branchRow,
          total_point: totalPoint,
          tp_4L: totalTp4L,
        }
      })
      : rowsByTab

    return {
      rowWithPoints: rowWithValue.map(row => {
        const editingRow = editingRows.find(item => item.competition_row_id === row.competition_row_id)

        return ({
          ...row,
          is_editing: editingRow,
          userName: editingRow?.userName,
          userId: editingRow?.userId,
        })
      })
    }
  }, [branches, areas, members, isBranchType, editingRows, currentTab.id, currentAge])

  const fields = useMemo(getReportFields, [currentTab])
  const sortedBranches = [...branches]
    .filter(branch => branch.branch_name)
    .sort((a, b) => a[LEADERBOARD_BRANCH_TYPES.name]?.localeCompare(b[LEADERBOARD_BRANCH_TYPES.name]))
  const sortedAreas = [...areas]
    .filter(area => area.area_name)
    .filter(area => area.age_range === currentAge)
    .sort((a, b) => a[LEADERBOARD_GROUP_TYPES.name].localeCompare(b[LEADERBOARD_GROUP_TYPES.name]))

  return (
    <div className="input-data">
      <ul className="nav nav-tabs">
        {INPUT_TABS.map(tab => (
          <li key={tab.id} className="nav-item" onClick={() => handleChangeTab(tab)}>
            <div className={"nav-link " + (currentTab.id === tab.id && ' active')}>{tab.label}</div>
          </li>
        ))}
      </ul>

      <div className="input-block">
        {currentTab.id !== INPUT_TAB_VALUES.branch && (
          <div className="age-select">
            Theo độ tuổi
            <select onChange={e => handleChangeAge(e.target.value)} value={currentAge}>
              {LEADERBOARD_AGES.map(age => (
                <option key={age.id} value={age.id}>{age.label}</option>
              ))}
            </select>
          </div>
        )}
        <div className={"input-rows-scollable " + (currentTab.id !== INPUT_TAB_VALUES.branch && 'has-age-select')}>
          {rowWithPoints.map((row, rowIndex) => {
            const isEditing = row.is_editing && row.userId !== userAuth.user_id
            console.log('rơw', row)
            return (
              <div className={'input-row ' + (isEditing ? 'editing' : '')} key={row.competition_row_id} id={row.uuid}>
                <div className="row-heading">
                  <div className="row-label">#{rowIndex + 1}. {isEditing && `${row.userName} đang cập nhật...`}</div>
                  <div className="row-btns">
                    <div onClick={() => confirmDeleteRow(row)}>
                      <i className="icon-trash menu-icon" />
                    </div>
                  </div>
                </div>
                <ul className="input-list">
                  {fields.map(
                    (field, index) => {
                      if (field.key === LEADERBOARD_GROUP_TYPES.branch_uuid) {
                        return <li key={currentTab.id + index + currentAge}>
                          <label htmlFor={field.key}>{field.label}:</label> <select disabled={isEditing} onChange={(e) => handleChangeBranch(e.target.value, row)} value={row.branch_uuid}>
                            <option value=''>---chọn Chi Hội---</option>
                            {sortedBranches.map(branch => <option key={branch.uuid + branch.competition_row_id + INPUT_TAB_VALUES.branch} value={branch.uuid}>{branch[LEADERBOARD_BRANCH_TYPES.name]}</option>)}
                          </select>
                        </li>
                      }

                      if (field.key === LEADERBOARD_INDIVIDUAL_TYPES.group_uuid) {
                        return (
                          <li key={currentTab.id + index + currentAge}>
                            <label htmlFor={field.key}>{field.label}:</label> <select disabled={isEditing} onChange={(e) => handleChangeGroup(e.target.value, row)} value={row.area_uuid}>
                              <option value=''>---chọn Địa Vực---</option>
                              {sortedAreas.map(area => <option key={area.uuid + area.competition_row_id + INPUT_TAB_VALUES.group} value={area.uuid}>{area[LEADERBOARD_GROUP_TYPES.name]}</option>)}
                            </select>
                          </li>
                        )
                      }

                      if (currentTab.id === INPUT_TAB_VALUES.individual && field.key === LEADERBOARD_INDIVIDUAL_TYPES.is_completed) {
                        return (
                          <li key={row.competition_row_id + currentTab.id + field.key}>
                            <label htmlFor={row.competition_row_id + currentTab.id + field.key} className="label-checkbox">{field.label}:
                              <input
                                id={row.competition_row_id + currentTab.id + field.key}
                                type={field.type}
                                onChange={(e) => handleChangeField(!row?.[field.key], field.key, row)}
                                defaultChecked={row?.[field.key] === 1}
                              />
                            </label>
                          </li>
                        )
                      }

                      return (
                        <li key={row.competition_row_id + currentTab.id + field.key} className={field.readOnly && 'read-only'}>
                          <label htmlFor={row.competition_row_id + currentTab.id + field.key}>{field.label}:</label>
                          {isEditing
                            ? <span>{row?.[field.key]}</span>
                            : <input
                              id={row.competition_row_id + currentTab.id + field.key}
                              placeholder=''
                              type={field.type}
                              onChange={(e) => handleChangeField(e.target.value, field.key, row)}
                              defaultValue={(row?.[field.key] || '')}
                              readOnly={field.readOnly}
                              onFocus={() => setRowEditing(row, true)}
                              onBlur={() => setRowEditing(row, false)}
                              disabled={field.readOnly}
                            />
                          }
                        </li>
                      )
                    }
                  )}
                </ul>
              </div>
            )
          }
          )}
        </div>
        <div className='bottom-pad'>
          <div className="clickable" onClick={handleAddRow}>
            Thêm +1 hàng
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputData