import userConstant from "./user";

const sidebarConstant = {
  ERROR_PATH: [
    '/not-found',
    '/system-error',
    '/forbidden'
  ],
  SIDEBAR_LIST: [
    {
      name: 'Thi Đua',
      prefix: 'competition',
      image: 'icon-star',
      roles: [
        userConstant.ROLE_VALUES.HT,
        userConstant.ROLE_VALUES.CSN,
      ],
      url: '/competition',
      allowedUserIds: [35], // kvt Huy
    },
    {
      name: 'Tổng kết',
      prefix: 'summary',
      image: 'icon-paper-stack',
      roles: [
        userConstant.ROLE_VALUES.HT,
        userConstant.ROLE_VALUES.CSN,
        userConstant.ROLE_VALUES.NT,
        userConstant.ROLE_VALUES.KVT
      ],
      url: '/summary',
    },
    {
      name: 'Báo cáo',
      prefix: 'report',
      image: 'icon-paper',
      roles: [
        userConstant.ROLE_VALUES.HT,
        userConstant.ROLE_VALUES.CSN,
        userConstant.ROLE_VALUES.NT,
        userConstant.ROLE_VALUES.KVT
      ],
      url: '/report',
    },
    // {
    //   name: '2700 km',
    //   prefix: '2700',
    //   image: 'icon-map',
    //   roles: [],
    //   allowedUserIds: [2], // csn Kim
    //   url: '/2700',
    // },
    {
      name: 'WM Hà Nội',
      prefix: 'wm',
      image: 'icon-monitor',
      roles: [],
      allowedUserIds: [2], // csn Kim
      url: '/wm',
    },
    // {
    //   name: 'Biệt đội',
    //   prefix: 'squad',
    //   image: 'icon-star',
    //   roles: [],
    //   allowedUserIds: [2], // csn Kim
    //   url: '/squad',
    // },
    {
      name: 'Biểu mẫu',
      prefix: 'form',
      image: 'icon-grid',
      roles: [
        userConstant.ROLE_VALUES.HT,
        userConstant.ROLE_VALUES.CSN,
        userConstant.ROLE_VALUES.NT,
        // userConstant.ROLE_VALUES.KVT
      ],
      // isHm: true,
      // isKvtAllowed: true,
      url: '/form',
    },
    {
      name: 'Thành viên',
      prefix: 'members',
      image: 'icon-head',
      roles: [
        userConstant.ROLE_VALUES.HT,
      ],
      allowedUserIds: [2], // csn Kim
      // isHm: true,
      url: '/members',
    },
    // {
    //   name: 'Thư viện',
    //   prefix: 'document',
    //   image: 'icon-paper',
    //   roles: [
    //     userConstant.ROLE_VALUES.NT,
    //     userConstant.ROLE_VALUES.KVT
    //   ],
    //   allowedUserIds: [2], // csn Kim
    //   isHm: true,
    //   url: '/document',
    // },
    {
      name: 'Cài đặt',
      prefix: 'settings',
      image: 'icon-cog',
      roles: [
        userConstant.ROLE_VALUES.HT,
        userConstant.ROLE_VALUES.CSN,
        userConstant.ROLE_VALUES.NT,
        userConstant.ROLE_VALUES.KVT
      ],
      url: '/settings',
    },
  ]
}

export default sidebarConstant